import { render, staticRenderFns } from "./Amount.vue?vue&type=template&id=53beb546&scoped=true&"
import script from "./Amount.vue?vue&type=script&lang=js&"
export * from "./Amount.vue?vue&type=script&lang=js&"
import style0 from "./Amount.vue?vue&type=style&index=0&id=53beb546&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53beb546",
  null
  
)

export default component.exports